<template>
  <div class="studioadd">
		<div class="maincontent">
			<div class="selectgroup">
				<div class="mc_div">
					<div class="mcd_content">
						<div class="imgAvatar" v-if="!form.avatar" @click="handleImage">
							<i class="iconfont icon-tianjiatupian_huaban"></i>
				
						</div>
						<div class="imgAvataru" style="border: none" v-else @click="handleImage">
							<!-- <div class="deleImg" @click="handleDelImg">X</div> -->
							<img :src="form.avatar+$root.img_small" alt />
							<i class="iconfont icon-tianjiatupian_huaban"></i>
						</div>
					</div>
				</div>
				<div class="mc_div">
					<div class="mcd_title">
						<span style="color: #fd3d1e">*</span>
						<span>姓名</span>
					</div>
					<div class="mcd_content">
						<input placeholder="请输入" v-model="form.real_name" />
					</div>
				</div>

				<div class="mc_div">
					<div class="mcd_title">
						<span style="color: #fd3d1e">*</span>
						<span>联系方式</span>
					</div>
					<div class="mcd_content">
						<input type="tel" placeholder="请输入" v-model="form.telphone" />
					</div>
				</div>

				<div class="sure_add" v-if="form.name == '' || form.telphone == ''">确认编辑</div>
				<div class="sure_add" v-else @click="sureCreate" style="background-color: #4E89FF">确认编辑</div>
			</div>
		</div>
  </div>
</template>

<script>
import until from "@/api/until.js";
import studio_api from "@/api/teaching_studio_teaching_studio_api.js";
import tool_api from "@/api/teaching_studio_tool.js";
import wx from "weixin-js-sdk";
import studio_login_api from "@/api/teaching_studio_no_login.js";
export default {
  name: "studioadd",
  data() {
    return {
      showDatePicker: false,
      minDate: new Date(1999, 0, 1),
      maxDate: new Date(),
      dateItem: {},
      currentTime: new Date(),
      awardsList: [],
      form: {
        avatar: "",
        real_name: "",
        sex: "",
        telphone: "",
        work_unit: "",
        awards: []
      },
      allMemberList: [],
      awardJson: {
        creatTime: "",
        contentText: "",
        contentImgs: []
      }
    };
  },
  components: {},
  mounted() {
    this.getMemberInfo();
    this.getWXConfig();
    document.title = "完善个人档案";
  },
  methods: {
    //获取jssdktoekn
    async getWXConfig() {
		let url = encodeURI(location.href.split("#")[0]);
		let app_id = this.$root.app_id;
		const data = await studio_login_api.Api_nologinWechatJssdkPost({
			url,
			app_id
		});
		if (data.errcode == 0) {
			let jsonData = JSON.parse(data.other);
			wx.config({
				debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: jsonData.app_id, // 必填，公众号的唯一标识
				timestamp: jsonData.timestamp, // 必填，生成签名的时间戳
				nonceStr: jsonData.nonce_str, // 必填，生成签名的随机串
				signature: jsonData.signature, // 必填，签名
				jsApiList: [
					"chooseImage",
					"uploadImage",
				]
			});
			let _this = this;
			wx.ready(function() {});
			wx.error(function(res) {});
		} else {
			this.$toast(data.msg);
		}
    },

    handleImage() {
		let _this = this;
		$(".ql-editor").blur();
		setTimeout(() => {
			wx.chooseImage({
			count: 1,
			sizeType: ["original", "compressed"],
			sourceType: ["album", "camera"],
			success: function(data) {
				let localIds = data.localIds;

				_this.wxuploadImage(localIds, 0);
			}
			});
		}, 500);
    },
    wxuploadImage(localIds, index) {
		let _this = this;
		this.$toast.loading({
			message: "加载中...",
			forbidClick: false,
			duration: 0
		});
		wx.uploadImage({
			localId: localIds[index],
			isShowProgressTips: 0,
			success: function(res) {
			_this.fileUpload(res.serverId, index, localIds);
				if (index < localIds.length - 1) {
					let indexNext = index + 1;
					_this.wxuploadImage(localIds, indexNext);
				}
			}
		});
    },
    async fileUpload(mediaId, index, localIds) {
		let app_id = this.$root.app_id;
		let media_id = mediaId;
		let media_type = "img";
		let data = await studio_api.Teaching_studio_apiMediaWechatUpPost({media_id,media_type,app_id});
		if (data.errcode == 0) {
			this.form.avatar = data.other;
			if (index == localIds.length - 1) {
				this.$toast("上传成功");
				this.$toast.clear();
			}
		}
    },
    // 个人信息
    async getMemberInfo() {
		let app_id = this.$root.app_id;
		this.$toast.loading({
			message: "加载中...",
			forbidClick: true,
			duration: 0
		});
		let data = await studio_api.Teaching_studio_apiMemberLogin_userGet({app_id});
		if (data.errcode == 0) {
			this.$toast.clear();
			this.allMemberList = data.other;
			this.form.avatar = data.other.avatar;
			this.form.real_name = data.other.name;
			this.form.telphone = data.other.telphone;
			this.form.user_id=data.other.user_id
		}
    },
    //完善个人档案
    async sureCreate() {
		let computer_code = until.getCookie("guid").substring(0, 6);
		this.$toast.loading({
			message: "加载中...",
			forbidClick: true,
			duration: 0
		});

		let data = await tool_api.ToolForm_saltGet({ computer_code });
		if (data.errcode == 0) {
			this.upDateAvatar(data.other);
		}
 
		
    },
    //更新头像
    async upDateAvatar(salt) {
		let app_id = this.$root.app_id;
		let computer_code = until.getCookie("guid").substring(0, 6);
		let avatar = this.form.avatar;
		let user_id = this.form.user_id;
		let data = await studio_api.Teaching_studio_apiUserUpdateAvatarPost({app_id,computer_code,avatar,salt,user_id});
		if (data.errcode == 0) {
			let dataNext = await tool_api.ToolForm_saltGet({ computer_code });
			if (dataNext.errcode == 0) {
				this.upDateName(dataNext.other);
			}
			
		}
    },
    //更新姓名
    async upDateName(salt) {
		let app_id = this.$root.app_id;
		let computer_code = until.getCookie("guid").substring(0, 6);
		let real_name = this.form.real_name;
		let user_id = this.form.user_id;
		let data = await studio_api.Teaching_studio_apiUserUpdateReal_namePost({app_id,computer_code,real_name,salt,user_id});
		if (data.errcode == 0) {
			let dataNext = await tool_api.ToolForm_saltGet({ computer_code });
			if (dataNext.errcode == 0) {
				this.upDateTel(dataNext.other);
			}
		}
    },
   
    // 更新电话
    async upDateTel(salt) {
		let app_id = this.$root.app_id;
		let computer_code = until.getCookie("guid").substring(0, 6);
		let telphone = this.form.telphone;
		let user_id = this.form.user_id;
		let data = await studio_api.Teaching_studio_apiUserUpdateTelphonePost({app_id,computer_code,telphone,salt,user_id});
		if (data.errcode == 0) {
			setTimeout(() => {
				this.$toast("更新成功");
				this.$root.isLoading = true;
				window.history.go(-1);
			}, 1000);
		}
    },
    
  }
};
</script>
<style scoped>
.studioadd {
  width: 100%;
  height: 100vh;
  display: inline-block;
}

.selectgroup {
  margin-top: 60px;
}

.slectgroupTwo {
  margin-top: 60px;
  display: none;
}

.mc_div {
  width: 100%;
  display: inline-block;
  margin-top: 40px;
}

:first-child.mc_div {
  margin-top: 0;
}

.mcd_title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 26px;
  color: #666666;
}

.mcd_title span:first-child {
  margin-right: 8px;
}

.mcd_content {
  width: 100%;
  margin-top: 16px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
}

.mcd_content input {
  width: 100%;
  height: 88px;
  border: 1px solid #e6e6e6;
  background: #ffffff;
  border-radius: 8px;
  padding-left: 30px;
  box-sizing: border-box;
  font-size: 26px;
}

.mcdc_addimg {
  width: 145px;
  height: 145px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  text-align: center;
  position: relative;
  left: 40%;
}

.imgAvatar {
  height: 145px;
  width: 145px;
  text-align: center;
  border: 1px solid #e6e6e6;
  position: relative;
  left: 40%;
}

.imgAvatar img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.imgAvataru {
  height: 145px;
  width: 145px;
  text-align: center;
  border: 1px solid #e6e6e6;
  position: relative;
  left: 40%;
}

.imgAvataru img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.imgAvataru i {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 26px;
  background-color: #4E89FF;
  color: #ffffff;
  border-radius: 50%;
  padding: 8px;
}

.mcdc_addimg img {
  height: 100%;
  width: 100%;
}

.imgAvatar span {
  position: relative;
  top: 30%;
  font-size: 26px;
  font-weight: 400;
  color: #999999;
}

.mcdc_addimg .iconfont {
  position: relative;
  top: 30%;
  font-size: 48px;
  color: #999999;
}

.imgAvatar .iconfont {
  position: relative;
  top: 30%;
  font-size: 48px;
  color: #999999;
}

.cansure {
  background: #79ca6a;
}

.sexRadio {
  width: 100%;
  display: flex;
  font-size: 28px;
}

.sexRadio div {
  text-align: center;
  line-height: 88px;
  width: 295px;
  height: 88px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  margin-right: 40px;
  color: #999999;
}

.sex_div {
  background: #f5fff3 !important;
  border: 1px solid #79ca6a !important;
  color: #79ca6a !important;
}

.sure_add {
  /* width: 100%; */
  margin: 60px;
}

.icon-xiangyou {
  position: absolute;
  right: 10%;
}

.stepButton {
  margin-top: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
  margin: 60px;
}

.stepButton div {
  width: 194px;
  height: 80px;
  border-radius: 16px;
  text-align: center;
  line-height: 80px;
  margin-right: 24px;
}

.contentText {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  padding: 26px 30px;
}

.deleImg {
  height: 30px;
  width: 30px;
  background: red;
  color: #e6e6e6;
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
  position: relative;
  left: 145px;
}

.awardsHistory {
  font-size: 26px;
  margin-bottom: 12px;
  color: #999999;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  text-align: center;
}

.awardsHistory span {
  margin-right: 12px;
}

.awardsHistory img {
  height: 142px;
  width: 145px;
}

.delHistory {
  height: 20px;
  width: 20px;
  line-height: 20px;
  background-color: #999999;
  border-radius: 50%;
  padding: 8px;
  color: #ffffff;
  font-size: 5px;
}
</style>
